<template>
  <base-section
    id="pro-features2"
  >
    <base-section-heading title="Политика конфиденциальности в области обработки персональных данных" />
    <v-container style="font-size: large">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
        >
          <p>
            Настоящая политика (далее — «Политика») описывает принципы и порядок обработки персональных данных ООО «Айтимус» ОГРН 1213900008541, ИНН 3906404216 (далее — «Общество»), расположенным по адресу: 236006, Калининградская область, г Калининград, Юбилейная ул, д. 10, помещ. III этаж/кабинет 4/412, а также реализованные Обществом меры защиты персональных данных. Настоящая Политика размещается во всех местах сбора персональных данных Обществом и рекомендована к ознакомлению всем субъектам персональных данных, предоставляющим свои данные Обществу.
          </p>
          <p>
            ООО «Айтимус» обрабатывает и защищает персональные данные пользователей Сервиса Айтимс в соответствии с этой политикой. Регистрируясь в «Айтимс», вы даете согласие на обработку ваших данных на условиях этой политики.
          </p>
          <p>
            При пользовании Сервисом Пользователь подтверждает согласие с данной Политикой конфиденциальности и обработки персональных данных и ее условиями в полном объеме.
          </p>
          <p>
            Частичное принятие условий недопустимо.

          </p>
          <p>
            В настоящую Политику возможны внесение изменений, которые являются обязательными для Пользователя. Новая редакция Политики вступает в силу с момента ее размещения на сайте itmuse.ru и в Мобильном Приложении Айтимс, если иное не предусмотрено новой редакцией Политики.

          </p>

          <p>
            Термины и определения
          </p>
          <p>
            Общество — ООО «Айтимус», ОГРН 1213900008541, ИНН 3906404216, являющееся правообладателем Сервиса.
          </p>
          <p>
            Сервис — мобильное приложение «Айтимс».
          </p>

          <p>
            Персональные данные — любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
            Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
          </p>

          <ul>
            <li>
              1. Предмет обрабатываемых персональных данных
            </li>
            <li>
              1.1. Предметом настоящей Политики являются обязательства Сторон по обеспечению конфиденциальности и неразглашения Конфиденциальной информации при сотрудничестве сторон с использованием Сервиса.
            </li>
            <li>
              1.2. Общество осуществляет обработку только тех персональных данных, которые:
              были предоставлены Обществу субъектами персональных данных;
              были предоставлены Обществу третьими лицами с согласия субъектов персональных данных;
              были размещены в открытом доступе субъектом персональных данных либо с его согласия.
            </li>
            <li>
              1.3. Конфиденциальная информация может быть передана Пользователю в любой возможной форме, в том числе с использованием технических средств Сервиса, либо получена от Клиента при оказании услуг. Передача (получение) Конфиденциальной информации может осуществляться как лично, а также иными возможными удобными способами.
            </li>
          </ul>

          <ul>
            <li>
              2. Цели обработки
            </li>
            <li>
              2.1. Обработка персональных данных, необходима для предоставления возможности пользоваться услугами Сервиса, создания заданий, откликов, заключения и исполнения договоров услуг между Исполнителями и Заказчиками, получение технической поддержки.
              Также для предотвращения нарушений правил Сервиса или законодательства и обеспечения стабильной работы и безопасности Сервиса.
            </li>
          </ul>

          <ul>
            <li>
              3. Обращение с конфиденциальной информацией
            </li>
            <li>
              3.1. Передача Конфиденциальной информации по открытым каналам связи запрещена.
            </li>
            <li>
              3.2. Любая из Сторон обязана в течение одних суток с момента обнаружения признаков несанкционированного доступа третьих лиц к информации, составляющей коммерческую тайну, уведомить об этом другую Сторону и принять все возможные меры для уменьшения последствий несанкционированного доступа.
            </li>
            <li>
              3.3. Пользователи обязаны принять меры для предотвращения несанкционированного доступа к информации, относящейся к конфиденциальной, или ее передачи третьим лицам с нарушением условий настоящей Политики.
            </li>
            <li>
              3.4. Пользователь обязуется обеспечить надлежащее хранение Конфиденциальной информации и несет ответственность за разглашение информации любым третьим лицам.
            </li>
            <li>
              3.5. При обработке персональных данных в электронном виде реализуются организационные, правовые и технические меры защиты, исключающие возможность несанкционированного доступа к персональным данным со стороны лиц, не допущенных к их обработке.
            </li>
          </ul>

          <ul>
            <li>
              4. Персональные данные
            </li>
            <li>
              4.1. Общество обрабатывает персональные данные Пользователей, зарегистрированных на Сервисе, в порядке и на условиях, установленных настоящей Политикой, а также законодательством Российской Федерации.
            </li>
            <li>
              4.2. При регистрации и заполнении личных данных на Сервисе Пользователь подтверждает и гарантирует, что является собственником данных, указанных при регистрации, обладает всеми правами, позволяющими пройти регистрацию на Сервисе, все указанные данные правдивы и достоверны. Не допускается указания данных, не принадлежащих Пользователю или в отношении которых у Пользователя нет согласия на их использование. Общество не осуществляет проверку правдивости и/или корректности представляемых Пользователем данных и не несет за это ответственности.
            </li>
            <li>
              4.3. При предоставлении персональных данных третьих лиц Пользователем (включая данные сотрудников, родственников и т.д.), Пользователь гарантирует получение согласия субъектов персональных данных на обработку их персональных данных, включая передачу данных Обществу для целей обработки. При необходимости Пользователь обязуется предоставить Обществу по его требованию в трехдневный срок согласие субъектов персональных данных на обработку их персональных данных. Согласие субъектов персональных данных на обработку их персональных данных оформляется в соответствии со статьей 9 Федерального закона РФ «О персональных данных» №152-ФЗ от 27.07.2006 года.
            </li>
            <li>
              4.4. Пользователь в любой момент может отозвать согласие путем направления отзыва, составленного в свободной форме, но позволяющего идентифицировать Пользователя, на электронный адрес: support@itmuse.ru. После отзыва Согласия Личный кабинет Пользователя блокируется, но при этом, Общество оставляет за собой право хранить информацию о Пользователе для исполнения норм действующего законодательства, в том числе с целью подтверждения факта сотрудничества сторон.
            </li>
          </ul>
          <ul>
            <li>
              5. Сроки обработки
            </li>
            <li>
              5.1. Обработка персональных данных Пользователей осуществляется до прекращения деятельности Общества как юридического лица. Сроки обработки персональных данных в иных случаях определяются целями обработки персональных данных и/или отдельными согласиями субъектов персональных данных.
            </li>
            <li>
              5.2. Обработка персональных данных (в том числе, их хранение) подлежит прекращению по достижении целей обработки соответствующих данных, а также в случае отпадения оснований для обработки персональных данных (в том числе, в случае отзыва ранее предоставленного согласия на обработку персональных данных, если законодательство не предоставляет Обществу право продолжить обработку персональных данных).
            </li>
          </ul>
          <ul>
            <li>
              6. Безопасность
            </li>
            <li>
              6.1. Для обеспечения безопасности персональных проводятся необходимые и достаточные правовые, организационные и технические меры.
            </li>
            <li>
              6.2. При выявлении угрозы безопасности при обработке персональных данных в информационных системах проводится оценка вреда, который может быть причинен в случае нарушения безопасности и выполняются требования законодательства в отношении защиты персональных данных.
            </li>
            <li>
              6.3. При разглашении Конфиденциальной информации или наличии угрозы разглашения Пользователь обязан незамедлительно уведомить об этом Общество.
            </li>
            <li>
              6.4. Общество вправе проводить проверки соблюдения Пользователем обязательств по защите Конфиденциальной информации.
            </li>
          </ul>

          <ul>
            <li>
              7. Обратная связь
            </li>
          </ul>
          <p>
            При возникновении вопросов или необходимости отзыва согласия на обработку персональных данных, напишите нам на электронный адрес:
            <a href="mailto:support@itmuse.ru">support@itmuse.ru</a>.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: 'SectionProFeat',

  data: () => ({
    features: [
    ],
  }),
}
</script>

<style scoped>
ul{
  list-style-type: none;
}
li:first-child {
  margin-left: -15px !important;
}
</style>
